import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ReactGA from 'react-ga4';
import CookieConsent from './components/CookieConsent';
import ScrollToTop from './components/ScrollToTop';
import { NO_BACK_LINKS } from './constants/AppUrlConstants';
import { siteMaintenance, ga4MeasurementId } from './constants/config';
import Banner from './layout/Banner';
import Footer from './layout/Footer';
import Header from './layout/Header';
import SiteMaintenance from './pages/landing/SiteMaintenance';
import AppRouter from './AppRouter';
import SkipLink from './layout/SkipLink';

const Main = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const pageWithoutBackLink = NO_BACK_LINKS.includes(pathname);
  const [cookiePreference, setCookiePreference] = useState(() => {
    const useCookies = sessionStorage.getItem('useCookies')
      ? sessionStorage.getItem('useCookies')
      : false;
    return {
      actionTaken: !!useCookies,
      useCookies: JSON.parse(useCookies),
    };
  });

  useEffect(() => {
    if (!cookiePreference.useCookies) {
      window[`ga-disable-${ga4MeasurementId}`] = true; // use cookies is false, therefore disable is true
    } else {
      window[`ga-disable-${ga4MeasurementId}`] = false;
      ReactGA.send('pageview');
    }
  }, [cookiePreference.useCookies, pathname]);

  if (siteMaintenance) {
    return (
      <>
        <SkipLink />
        <Header />
        <div className="govuk-width-container">
          <main
            id="main-content"
            tabIndex="-1"
            className="govuk-main-wrapper govuk-main-wrapper--auto-spacing"
            role="main"
            aria-live="polite"
          >
            <SiteMaintenance />
          </main>
        </div>
        <Footer />
      </>
    );
  }

  return (
    <>
      {!cookiePreference.actionTaken && <CookieConsent setState={setCookiePreference} />}
      <ScrollToTop />
      <SkipLink />
      <Header />
      <div className="govuk-width-container">
        <Banner />
        {/* Back link with logic as to when it should/should not show; */}
        {!pageWithoutBackLink && (
          <nav aria-label="Back link" id="backLink">
            <a
              href="#back"
              className="govuk-back-link"
              onClick={(e) => {
                e.preventDefault();
                navigate(-1);
              }}
            >
              Back
            </a>
          </nav>
        )}
        <main
          id="main-content"
          tabIndex="-1"
          className="govuk-main-wrapper govuk-main-wrapper--auto-spacing"
          role="main"
          aria-live="polite"
          data-testid="main-content"
        >
          <AppRouter />
        </main>
      </div>
      <Footer />
    </>
  );
};

export default Main;
